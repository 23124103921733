import ReactDOM from 'react-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngular,
  faAws,
  faCss3Alt,
  faDev,
  faGitAlt,
  faGithub,
  faGulp,
  faHtml5,
  faJs,
  faLaravel,
  faNode,
  faPhp,
  faReact,
} from '@fortawesome/free-brands-svg-icons';
import {
  faBolt,
  faBriefcase,
  faCircleNotch,
  faCode,
  faCoffee,
  faDatabase,
  faExternalLinkAlt,
  faFire,
  faHeart,
  faLanguage,
  faPaperPlane,
  faPhone,
  faPlayCircle,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPhp,
  faJs,
  faHtml5,
  faCss3Alt,
  faBriefcase,
  faExternalLinkAlt,
  faDatabase,
  faCode,
  faCoffee,
  faLaravel,
  faReact,
  faAngular,
  faNode,
  faGulp,
  faGitAlt,
  faFire,
  faBolt,
  faLanguage,
  faHeart,
  faWrench,
  faGithub,
  faPhone,
  faPaperPlane,
  faCircleNotch,
  faAws,
  faDev,
  faPlayCircle,
);
